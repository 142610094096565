import React from "react";
import { Review } from "./review";

interface ReviewsProps {}

export const Reviews = ({}: ReviewsProps) => {
  const reviews = [
    {
      nbStars: 5,
      customerName: "Diane Highmore",
      reviewText:
      "My home needed a thorough deep clean and that is exactly what I got from Gold Shine Cleaning. They went above and beyond what I expected! I have already started spreading the word about it to family and friends. It's easy to support business in your own community when they produce such outstanding results. I will be calling Gold Shine regularly!",
      userSince: "11/14/2024",
    },
    {
      nbStars: 5,
      customerName: "Gurjit Kaur",
      reviewText:
        "I highly recommend gold shine cleaning company for anyone looking for high-quality cleaning services. Very neat and clean I’ll definitely be using them again for regular cleanings!",
      userSince: "8/11/2024",
    },
    {
      nbStars: 5,
      customerName: "Lisa Nodwell",
      reviewText:
        "My thanks to Gold Shine Cleaning. My home needed a deep clean and the service I received was excellent! Very professional, fast and thorough. I would highly recommend Gold Shine Cleaning.",
      userSince: "11/14/2024",
    },
  ];
  
  return (
    <div
      id="reviews"
      className="min-w-full flex flex-col p-3"
      data-index={1}
    >
      <div className="md:w-1/2 md:mx-auto">
        <h1 className="text-white text-center text-lg my-8 mt-12">
          Our valued customers told us that..
        </h1>
        <div className="">
          {reviews.map((review: any, key: any) => {
            return (
              <Review
                align={key % 2 === 0 ? "left" : "right"}
                reviewDetails={review}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
